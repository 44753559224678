.weekly-title {
  height: 5.75rem;
}

#contents-slider > .slick-slider > .slick-list {
  height: 100%;
}

#contents-slider > .slick-slider > .slick-list > .slick-track {
  height: 100%;
}

#contents-slider > .slick-slider > .slick-list > .slick-track > .slick-slide {
  position: relative;
}

#contents-slider
  > .slick-slider
  > .slick-list
  > .slick-track
  > .slick-slide
  > div {
  height: calc(100% - 6px);
}

#contents-slider
  > .slick-slider
  > .slick-list
  > .slick-track
  > .slick-slide:nth-child(2) {
  left: -1rem;
}

#contents-slider
  > .slick-slider
  > .slick-list
  > .slick-track
  > .slick-slide:nth-child(3) {
  left: -2rem;
}

#contents-slider
  > .slick-slider
  > .slick-list
  > .slick-track
  > .slick-slide:nth-child(4) {
  left: -3rem;
}

#contents-slider
  > .slick-slider
  > .slick-list
  > .slick-track
  > .slick-slide:nth-child(5) {
  left: -4rem;
}

#contents-slider
  > .slick-slider
  > .slick-list
  > .slick-track
  > .slick-slide:nth-child(6) {
  left: -5rem;
}

.weekly-bottom {
  position: sticky;
  left: 0;
  bottom: 0;
  background-color: white;
  z-index: 99;
  height: 4.75rem;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.06);
}

.trip-bg {
  width: 100%;
  margin-left: 3px;
}

.weekly-trip-detail-min-height {
  min-height: 8.5rem;
}

.weekly-poi-blog-descriptino-width {
  width: calc(100% - 4rem - 0.5rem);
}

@media (min-width: 640px) {
  .weekly-title {
    height: 8.625rem;
  }
  .weekly-trip-detail-min-height {
    min-height: 12.75rem;
  }

  #contents-slider
    > .slick-slider
    > .slick-list
    > .slick-track
    > .slick-slide:nth-child(8) {
    left: -1.5rem;
  }

  #contents-slider
    > .slick-slider
    > .slick-list
    > .slick-track
    > .slick-slide:nth-child(9) {
    left: -3rem;
  }

  #contents-slider
    > .slick-slider
    > .slick-list
    > .slick-track
    > .slick-slide:nth-child(10) {
    left: -4.5rem;
  }

  #contents-slider
    > .slick-slider
    > .slick-list
    > .slick-track
    > .slick-slide:nth-child(11) {
    left: -6rem;
  }

  #contents-slider
    > .slick-slider
    > .slick-list
    > .slick-track
    > .slick-slide:nth-child(12) {
    left: -7.5rem;
  }

  .weekly-bottom {
    left: 11rem;
    height: 7.125rem;
  }

  .trip-bg {
    width: calc(100% - 2px);
    margin-left: 4px;
  }

  .weekly-poi-blog-descriptino-width {
    width: calc(100% - 6rem - 0.75rem);
  }
}
